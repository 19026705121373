import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Link from "next/link";
import Logo from "@/components/Logo";
import clsx from "clsx";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function DesktopNavItem({ title, items, href, dropdownClassNames, children }) {
    return (
       <>
           {items ?
               <Popover className="relative">
                   <Popover.Button className="flex items-center gap-x-1 text-xs font-semibold leading-6 text-gray-900">
                       {title}
                       {/*<ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />*/}
                   </Popover.Button>
                   <Dropdown items={items} dropdownClassNames={dropdownClassNames} />
               </Popover>
               : <a href={href} className="text-xs font-semibold leading-6 text-gray-900">{title || children}</a>}
       </>
    )
}
function Dropdown({ items, dropdownClassNames }) {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
        >
            <Popover.Panel className={clsx('absolute -left-8 top-full z-50 mt-3 w-screen max-w-max overflow-hidden rounded bg-white shadow-lg ring-1 ring-brown-100/5',
                dropdownClassNames ? dropdownClassNames : null)}>
                <div className="p-4">
                    {items.map((item) => (
                        <div
                            key={item.name}
                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                            <div className="flex-auto">
                                <a href={item.href} className="block font-semibold text-gray-900">
                                    {item.name}
                                    <span className="absolute inset-0" />
                                </a>
                                {/*<p className="mt-1 text-gray-600">{item.description}</p>*/}
                            </div>
                        </div>
                    ))}
                </div>
            </Popover.Panel>
        </Transition>
    )
}

function MobileNavItem({ title, items, href, children }) {
    return (
        <>
            {items ?
                <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full items-center justify-between rounded py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                {title}
                                <ChevronDownIcon
                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                    aria-hidden="true"
                                />
                            </Disclosure.Button>
                            <MobileDropdown items={items} />
                        </>
                    )}
                </Disclosure> :
                <a href={href} className="-mx-3 block rounded py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{title || children}</a>
            }
        </>
    )
}

function MobileDropdown({ items, classNames }) {
    return (
        <Disclosure.Panel className="mt-2 space-y-2">
            {items.map((item) => (
                <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={clsx('block rounded py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50',
                        classNames ? classNames : null)}
                >
                    {item.name}
                </Disclosure.Button>
            ))}
        </Disclosure.Panel>
    )
}
export default function Navigation() {
    // const plan = [
    //     { name: 'Find a park', href: '/' },
    //     { name: 'Passes', href: 'https://www.nps.gov/planyourvisit/passes.htm', external: true },
    // ]
    const learn = [
        { name: 'Topics', href: '/topics' },
        { name: 'Articles', href: '/articles' }
    ]
    const explore = [
        { name: 'Tours', href: '/tours' },
        { name: 'Things to Do', href: '/things-to-do' },
        { name: 'Webcams', href: '/webcams' }
    ]
    const involved = [
        { name: 'Donate', href: 'https://www.nps.gov/getinvolved/donate.htm', external: true },
        { name: 'Partner', href: 'https://www.nps.gov/getinvolved/partner.htm', external: true },
        { name: 'Volunteer', href: 'https://www.nps.gov/getinvolved/volunteer.htm', external: true },
        { name: 'Work for the NPS', href: 'https://www.nps.gov/aboutus/workwithus.htm', external: true },
        { name: 'Community Resources', href: 'https://www.nps.gov/getinvolved/communities.htm', external: true }
    ]

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-beige-30">
            <nav className="max-w-8xl mx-auto px-6 py-6 sm:px-6 md:px-8 flex place-content-between" aria-label="Global navigation">
                <div className="text-brown-200 flex lg:flex-1">
                    <Link href="/" aria-label="Explore National Parks Home">
                        <Logo />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex items-center lg:gap-x-12">
                    <DesktopNavItem href="/">Find a Park</DesktopNavItem>
                    <DesktopNavItem href="/events">Events</DesktopNavItem>

                    {/*<DesktopNavItem title="Plan" items={plan} />*/}
                    {/*<DesktopNavItem title="Explore" items={explore} />*/}
                    {/*<DesktopNavItem title="Learn" items={learn} />*/}
                    <DesktopNavItem title="Get Involved" items={involved} dropdownClassNames="left-auto right-0" />
                </Popover.Group>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel focus="true" className="fixed inset-y-0 right-0 z-50 w-screen overflow-y-auto bg-beige-200 px-6 py-6">
                    <div className="flex items-center justify-between">
                        <div className="text-brown-200 flex lg:flex-1">
                            <Link href="/">
                                <Logo />
                            </Link>
                        </div>
                        <button
                            type="button"
                            className="-m-2.5 rounded p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-brown-100/10">
                            <div className="space-y-2 py-6">
                                <MobileNavItem href="/">Find a Park</MobileNavItem>
                                <MobileNavItem href="/events">Events</MobileNavItem>
                                <MobileNavItem title="Get Involved" items={involved} dropdown="true" />
                                {/*<MobileNavItem title="Plan" items={plan} dropdown="true" />*/}
                                {/*<MobileNavItem title="Explore" items={explore} dropdown="true" />*/}
                                {/*<MobileNavItem title="Learn" items={learn} dropdown="true" />*/}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}